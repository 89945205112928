<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <CategoriesTop />
        <CategoriesList
          :displayedCategorys="category"
          :showLoader="showLoader"
        />
        <DatatableBottom :page="pager" :module="moduleName" :count="count" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import CategoriesTop from "../components/categories/CategoriesTop.vue";
import CategoriesList from "../components/categories/CategoriesList.vue";
import DatatableBottom from "../components/tableview/DatatableBottom.vue";
import axios from "axios";

export default {
  name: "Categories",

  components: {
    Navbar,
    LeftMenu,
    CategoriesTop,
    CategoriesList,
    DatatableBottom,
  },

  data: function() {
    return {
      moduleName: "",
      errorMessage: null,
      category: [],
      showLoader: 0,
      page: 0,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
    };
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.current_page) {
          this.showLoader = 1;
          this.fetchCategory(page);
        }
      },
    },
  },
  methods: {
    fetchCategory(page) {
      axios
        .post(`${process.env.VUE_APP_API_URL}category/listing?page=${page}`, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.category = response.data.data.data;
            this.pager = response.data.data;
            this.count = response.data.data.total;
            this.moduleName =
              response.data.data.total > 0 ? "categories" : "category";
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  mounted() {
    this.$root.$on("removeCategory", (id) => {
      const index = this.category.findIndex((c) => c.id === id);
      this.category.splice(index, 1);
      this.count = this.count - 1;
      this.pager.total = this.pager.total - 1;
    });

    this.$root.$on("reloadCategory", () => {
      this.fetchCategory(1);
    });
  },
};
</script>
