<template>
  <div>
    <EditCategory />
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Sr No.</th>
          <th scope="col" class="text-center">Category Code</th>
          <th scope="col" class="text-center">Category Name</th>
          <th scope="col" class="text-center">Products Count</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Actions</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedCategorys.length > 0">
        <tr v-for="(category, index) in displayedCategorys" :key="index">

         
            <td>
            <div class="company-link">
              <span>C-{{ index+1 }}</span>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span>{{ category.category_code }}</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span>{{ category.category_name }}</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span>{{ category.category_products_count }}</span>
            </div>
          </td>
          <td v-if="category.status == 1" class="text-center">
            <button class="active">Active</button>
          </td>
          <td v-else-if="category.status == 2" class="text-center">
            <button class="terminated">Not Active</button>
          </td>
          <td class="text-center">
            <div class="actions-btn">
              <i class="fa fa-pencil-alt" @click="callModal(category)"> </i>
              <i class="fa fa-trash" @click="deleteCategory(category.id)"> </i>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedCategorys.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import EditCategory from "./EditCategory.vue";
export default {
  name: "CategoriesList",
  props: {
    displayedCategorys: Array,
    showLoader: Number,
  },
  data() {
    return {
      API_URL: this.$categoryStorageURL,
    };
  },
  components: {
    EditCategory,
  },

  methods: {
    callModal: function(category) {
      this.$root.$emit("openEditCategory", category);
    },

    async deleteCategory(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Category ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(process.env.VUE_APP_API_URL + "category/delete", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removeCategory", id);
                this.$swal("Deleted!", "Category has been deleted.", "success");
              } else {
                alert(response.data.message);
              }
            });
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
