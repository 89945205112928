var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openModalAddCategories",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#AddCategoriesModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"AddCategoriesModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h6',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Create Product Category ")]),_c('button',{ref:"closeModalAddCategory",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.category_code.$model),expression:"$v.details.category_code.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.category_code.$error,
                            'is-valid': !_vm.$v.details.category_code.$invalid,
                          },attrs:{"type":"text","id":"category_code","placeholder":"Enter Category Code","autocomplete":"off"},domProps:{"value":(_vm.$v.details.category_code.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.category_code, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.category_code.required)?_c('span',[_vm._v("Category Code is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.category_name.$model),expression:"$v.details.category_name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                            'is-invalid': _vm.$v.details.category_name.$error,
                            'is-valid': !_vm.$v.details.category_name.$invalid,
                          },attrs:{"type":"text","id":"category_name","placeholder":"Enter Category Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.category_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.category_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.category_name.required)?_c('span',[_vm._v("Category Name is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"form-group d-flex align-items-center justify-content-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.status),expression:"details.status"}],staticClass:"mr-2",attrs:{"type":"checkbox","id":"supplierActive"},domProps:{"checked":Array.isArray(_vm.details.status)?_vm._i(_vm.details.status,null)>-1:(_vm.details.status)},on:{"change":function($event){var $$a=_vm.details.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.details, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.details, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.details, "status", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"supplierActive"}},[_vm._v("This category is active")])])])])])])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.Create(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Create")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"customer_name"}},[_vm._v("Category Code "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v("Category Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
}]

export { render, staticRenderFns }