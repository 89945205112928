<template>
  <div>
    <AddCategories />
    <div class="top-wrapper">
      <h6>Categories Management</h6>
      <button class="custom-btn2" @click="callModal()">
        Add Category
      </button>
    </div>
  </div>
</template>

<script>
import AddCategories from "./AddCategories.vue";
export default {
  name: "CategoriesTop",
  components: {
    AddCategories,
  },
  methods: {
    callModal: function() {
      this.$root.$emit("openAddCategories");
    },
  },
};
</script>

<style></style>
