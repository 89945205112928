<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditCategoryModal"
      ref="openModalEditCategory"
    ></button>
    <div
      class="modal fade"
      id="EditCategoryModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              Edit Product Category
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalEditCategory"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="customer_name"
                            >Category Code <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="category_code"
                            placeholder="Enter Category Code"
                            autocomplete="off"
                            v-model.trim="$v.details.category_code.$model"
                            :class="{
                              'is-invalid': $v.details.category_code.$error,
                              'is-valid': !$v.details.category_code.$invalid,
                            }"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.category_code.required"
                              >Category Code is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="contact_person"
                            >Category Name <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.category_name.$model"
                            class="form-control"
                            id="category_name"
                            placeholder="Enter Category Name"
                            :class="{
                              'is-invalid': $v.details.category_name.$error,
                              'is-valid': !$v.details.category_name.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.category_name.required"
                              >Category Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-12 px-0">
                        <div
                          class="form-group d-flex align-items-center justify-content-start"
                        >
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="details.status"
                            id="categoryActive"
                          />
                          <label class="form-check-label" for="categoryActive"
                            >This category is active</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2"
                @click="updateCategory(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Update</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import $ from "jquery";

export default {
  name: "EditCategory",
  data: function() {
    return {
      details: {
        category_code: "",
        category_name: "",
        status: "",
      },
      isLoadingArray: [],
    };
  },
  validations: {
    details: {
      category_code: {
        required,
      },
      category_name: {
        required,
      },
    },
  },
  methods: {
    updateCategory: function(index) {
      var formData = new FormData();
      formData.append("category_code", this.details.category_code);
      formData.append("id", this.details.id);
      formData.append("category_name", this.details.category_name);
      var dataStatus = this.details.status == true ? 1 : 2;
      formData.append("status", dataStatus);

      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "category/update", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$swal("Created!", "Category has been updated.", "success");
            this.$root.$emit("reloadCategory");
            var elem = this.$refs.closeModalEditCategory;
            if (elem) {
              elem.click();
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openEditCategory", (category) => {
      var elem = this.$refs.openModalEditCategory;
      if (elem) {
        this.details.id = category.id;
        this.details.category_code = category.category_code;
        this.details.category_name = category.category_name;
        elem.click();
        if (category.status == 1) {
          this.details.status = true;
          $("#categoryActive").prop("checked", true);
        } else {
          this.details.status = false;
          $("#categoryActive").prop("checked", false);
        }
      }
    });
  },
};
</script>

<style></style>
