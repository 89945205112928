<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddCategoriesModal"
      ref="openModalAddCategories"
    ></button>
    <div
      class="modal fade"
      id="AddCategoriesModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">
              Create Product Category
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddCategory"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="customer_name"
                            >Category Code <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="category_code"
                            placeholder="Enter Category Code"
                            autocomplete="off"
                            v-model.trim="$v.details.category_code.$model"
                            :class="{
                              'is-invalid': $v.details.category_code.$error,
                              'is-valid': !$v.details.category_code.$invalid,
                            }"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.category_code.required"
                              >Category Code is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="contact_person"
                            >Category Name <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.category_name.$model"
                            class="form-control"
                            id="category_name"
                            placeholder="Enter Category Name"
                            :class="{
                              'is-invalid': $v.details.category_name.$error,
                              'is-valid': !$v.details.category_name.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.category_name.required"
                              >Category Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-12 px-0">
                        <div
                          class="form-group d-flex align-items-center justify-content-start"
                        >
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="details.status"
                            id="supplierActive"
                          />
                          <label class="form-check-label" for="supplierActive"
                            >This category is active</label
                          >
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2"
                @click="Create(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Create</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "AddCategories",
  data: function() {
    return {
      details: {
        category_code: "",
        category_name: "",
        status:false,
      },
      isLoadingArray: [],
    };
  },
  validations: {
    details: {
      category_code: {
        required
      },
      category_name: {
        required,
      },
    },
  },
  methods: {
    Create: function(index) {
      var formData = new FormData();
      formData.append("category_code", this.details.category_code);
      formData.append("category_name", this.details.category_name);
      formData.append("status", this.details.status == true ? 1 : 2);

      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);

        axios
        .post(process.env.VUE_APP_API_URL + "category/create", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
             this.$swal("Created!", "Category has been added.", "success");
              this.details.category_code="";
              this.details.category_name= "";
              this.details.status=false;
              this.isLoadingArray= [],
             this.$root.$emit("reloadCategory");
              var elem = this.$refs.closeModalAddCategory;
              if(elem)
              {
                elem.click();
              }
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });

    },
  },
  mounted() {
    this.$root.$on("openAddCategories", () => {
      var elem = this.$refs.openModalAddCategories;
      if(elem)
      {
        elem.click();
      }
     
    });
  },
};
</script>

<style></style>
